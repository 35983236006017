const actions = {
  GET_NEWS: 'GET_NEWS',
  SET_NEWS: 'SET_NEWS',
  DELETE_NEWS: 'DELETE_NEWS',
  CREATE_NEWS: 'CREATE_NEWS',
  ERROR_MSG: 'ERROR_MSG_NEWS',
  SHOW_MODAL_NEWS: 'SHOW_MODAL_NEWS',
  HIDE_MODAL_NEWS: 'HIDE_MODAL_NEWS',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',

  getNews: data => {
    return {
      type: actions.GET_NEWS,
      data,
    };
  },

  setNews: data => {
    return {
      type: actions.SET_NEWS,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_NEWS,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_NEWS,
    };
  },

  createNews: data => {
    return {
      type: actions.CREATE_NEWS,
      data,
    };
  },

  deleteNews: data => {
    return {
      type: actions.DELETE_NEWS,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
};

export default actions;
