import actions from './actions';

const {
  GET_GROUP,
  GET_GROUPS,
  SET_GROUP,
  CREATE_GROUP,
  DELETE_GROUP,
  ERROR_MSG,
  SHOW_MODAL_GROUP,
  HIDE_MODAL_GROUP,
  SUCCESS_MSG,
  SET_LOADING_GROUP,
  GET_SINGLE_GROUP,
  GET_STUDENTS_GROUP,
  GET_DPL_GROUP,
  LOAD_DATA_GROUP,
  SET_CURRENT_STUDENT_GROUP,
} = actions;

const initialStateFilter = {
  mahasiswa: [],
  dpl: [],
  mhsLoading: true,
  dplLoading: true,
  groupsLoading: true,
  loading: true,
  pagination: {
    current: 1,
    pageSize: 10,
    position: 0,
    total: 0,
  },
  message: null,
  data: [],
  groups: [],
  currentStudentGroup: null,
};

// data:{
//   dpl: ---,
//   mahasiswa: [
//     {
//       nama:--,
//       id:--
//     }
//   ]
// }

const DplReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_STUDENTS_GROUP:
      return {
        ...state,
        mahasiswa: data.sort((a, b) => b.id_student - a.id_student),
        mhsLoading: false,
      };
    case GET_DPL_GROUP:
      return {
        ...state,
        dpl: data.sort((a, b) => b.id_dpl - a.id_dpl),
        dplLoading: false,
      };
    case GET_GROUP:
      return {
        ...state,
        data: data.groups.sort((a, b) => b.id_dpl - a.id_dpl),
        pagination: {
          ...data.pagination,
          total: data.total,
        },
        loading: false,
      };
    case GET_GROUPS:
      return {
        ...state,
        loading: false,
        groups: data,
        groupsLoading: false,
      };
    case GET_SINGLE_GROUP:
      return {
        ...state,
        singleStudent: data,
      };
    case CREATE_GROUP:
      return {
        ...state,
        data: [data, ...state.data],
        modal: {
          ...state.modal,
          visible: false,
        },
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        dpl: [],
        mahasiswa: [],
        message: SUCCESS_MSG,
      };
    case HIDE_MODAL_GROUP:
      return {
        ...state,
        dataForm: null,
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case SHOW_MODAL_GROUP:
      return {
        ...state,
        dataForm: data || null,
        modal: {
          ...state.modal,
          visible: true,
        },
      };
    case SET_GROUP:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id_student === val.id_student) {
            return data;
          }
          return val;
        }),
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case DELETE_GROUP:
      return {
        ...state,
        visible: false,
        data: state.data.filter(val => data !== val.id_dpl),
        pagination: {
          ...state.pagination,
          total: state.pagination.total - 1,
        },
        dpl: [],
        mahasiswa: [],
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    case SET_LOADING_GROUP:
      return {
        ...state,
        ...data,
      };
    case LOAD_DATA_GROUP:
      return {
        ...state,
        loading: data,
      };
    case SET_CURRENT_STUDENT_GROUP:
      return {
        ...state,
        currentStudentGroup: data,
        loading: false,
      };
    default:
      return state;
  }
};

export default DplReducers;
