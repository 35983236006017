import React from 'react'
import { Avatar } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import FeatherIcon from 'feather-icons-react'
import { UserOutlined } from '@ant-design/icons'
import { logOut } from '@my-redux/authentication/actionCreator'
import { getCapsChar } from '@my-utility'
import { InfoWraper, UserDropDwon } from './auth-info-style'
import { Popover } from '../../popup/popup'
import Heading from '../../heading/heading'

const AuthInfo = () => {
  const dispatch = useDispatch();
  const auth = useSelector(s => s.auth);

  const SignOut = e => {
    e.preventDefault();
    dispatch(logOut());
  };

  const getTitle = () => {
    switch (auth.role) {
      case 'mahasiswa':
        return auth.user.jurusan;
      case 'dpl':
        return 'DPL';
      default:
        return 'Admin';
    }
  };

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <div style={{ width: 46, height: 46, marginRight: 15 }}>
            {auth.authPhoto ? (
              <Avatar src={auth.authPhoto} size={46} />
            ) : (
              <Avatar style={{ backgroundColor: '#20C997' }}>
                {getCapsChar(auth.user.full_name || auth.user.username)}
              </Avatar>
            )}
          </div>
          <figcaption>
            <Heading as="h5">{auth.user.full_name || auth.user.username}</Heading>
            <p>{getTitle()}</p>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link to={`/${auth.role}/profile`}>
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  return (
    <InfoWraper>
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            {auth.authPhoto ? (
              <Avatar size={32} src={auth.authPhoto} />
            ) : (
              <Avatar size={32} style={{ paddingTop: '5px' }} icon={<UserOutlined />} />
            )}
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
