import actions from './actions';

const {
  GET_DPL_REPORT,
  SET_DPL_REPORT,
  CREATE_DPL_REPORT,
  DELETE_DPL_REPORT,
  ERROR_MSG,
  SHOW_MODAL_DPL_REPORT,
  HIDE_MODAL_DPL_REPORT,
  SET_LOADING,
  GET_SINGLE_DPL_REPORT,
} = actions;

const initialStateFilter = {
  loading: true,
  data: {},
};

const StudentReportReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_DPL_REPORT:
      return {
        ...state,
        loading: false,
        data: data || {},
      };
    case GET_SINGLE_DPL_REPORT:
      return {
        ...state,
        singleStudent: data,
        loading: false,
      };
    case CREATE_DPL_REPORT:
      return {
        ...state,
        loading: false,
        data,
      };
    case HIDE_MODAL_DPL_REPORT:
      return {
        ...state,
        dataForm: null,
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case SHOW_MODAL_DPL_REPORT:
      return {
        ...state,
        dataForm: data || null,
        modal: {
          ...state.modal,
          visible: true,
        },
      };
    case SET_DPL_REPORT:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id_student === val.id_student) {
            return data;
          }
          return val;
        }),
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case DELETE_DPL_REPORT:
      return {
        ...state,
        loading: false,
        data: {},
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default StudentReportReducers;
