import actions from './actions';

const {
  GET_PROKER,
  SET_PROKER,
  CREATE_PROKER,
  DELETE_PROKER,
  ERROR_MSG,
  SHOW_MODAL_PROKER,
  HIDE_MODAL_PROKER,
  SUCCESS_MSG,
  SET_LOADING_PROKER,
  REVISE_PROKER,
  APPROVED_PROKER,
  REJECTED_PROKER,
  NEED_REVISE_PROKER,
  COMMENT_PROKER,
} = actions;

const initialStateFilter = {
  pagination: {
    current: 1,
    pageSize: 10,
    position: 0,
    total: 0,
  },
  loading: true,
  modal: {
    visible: false,
    data_modal: {},
  },
  search: '',
  message: null,
  data: [],
};

const ProkerReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_PROKER:
      return {
        ...state,
        data: data.proker.sort((a, b) => b.id_program - a.id_program),
        pagination: {
          ...data.pagination,
          total: data.total,
        },
        loading: false,
        search: data.search ? data.search : '',
      };
    case CREATE_PROKER:
      return {
        ...state,
        data: [data, ...state.data],
        modal: {
          visible: false,
          data_modal: {},
        },
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        message: SUCCESS_MSG,
      };
    case HIDE_MODAL_PROKER:
      return {
        ...state,
        modal: {
          visible: false,
          data_modal: {},
        },
      };
    case SHOW_MODAL_PROKER:
      return {
        ...state,
        modal: {
          visible: true,
          data_modal: data,
        },
      };
    case SET_PROKER:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id_program === val.id_program) {
            return {
              ...data,
              attachment: data.attachment ? data.attachment.name : data.just_delete ? null : val.attachment,
            };
          }
          return val;
        }),
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case REVISE_PROKER:
      return {
        ...state,
        data: [
          data,
          ...state.data.map(val => {
            if (data.previous_id === val.id_program) {
              return {
                ...val,
                status: '4',
              };
            }
            return val;
          }),
        ],
        modal: {
          visible: false,
          data_modal: {},
        },
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        message: SUCCESS_MSG,
      };
    case DELETE_PROKER:
      return {
        ...state,
        data: state.data
          .filter(val => data.id_program !== val.id_program)
          .map(val => {
            if (data.revision !== '0') {
              return { ...val, status: '3' };
            }
            return val;
          }),
        pagination: {
          ...state.pagination,
          total: state.pagination.total - 1,
        },
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    case SET_LOADING_PROKER:
      return {
        ...state,
        loading: data,
      };
    case APPROVED_PROKER:
      return {
        ...state,
        data: state.data.map(val => {
          if (val.id_program === data) {
            val.status = '2';
          }
          return val;
        }),
        loading: false,
      };
    case REJECTED_PROKER:
      return {
        ...state,
        data: state.data.map(val => {
          if (val.id_program === data) {
            val.status = '1';
          }
          return val;
        }),
        loading: false,
      };
    case NEED_REVISE_PROKER:
      return {
        ...state,
        data: state.data.map(val => {
          if (val.id_program === data) {
            val.status = '3';
          }
          return val;
        }),
        loading: false,
      };
    case COMMENT_PROKER:
      return {
        ...state,
        data: state.data.map(val => {
          if (val.id_program === data.id) {
            val.comment = data.comment;
          }
          return val;
        }),
        loading: false,
      };
    default:
      return state;
  }
};

export default ProkerReducers;
