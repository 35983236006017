const actions = {
  GET_LOGBOOK: 'GET_LOGBOOK',
  GET_STUDENT_LOGBOOK: 'GET_STUDENT_LOGBOOK',
  SET_LOGBOOK: 'SET_LOGBOOK',
  ADD_NOTES_MHS: 'ADD_NOTES_MHS',
  DELETE_LOGBOOK: 'DELETE_LOGBOOK',
  CREATE_LOGBOOK: 'CREATE_LOGBOOK',
  ERROR_MSG: 'ERROR_MSG_LOGBOOK',
  SHOW_MODAL_LOGBOOK: 'SHOW_MODAL_LOGBOOK',
  HIDE_MODAL_LOGBOOK: 'HIDE_MODAL_LOGBOOK',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING_LOGBOOK: 'SET_LOADING_LOGBOOK',
  GET_SINGLE_LOGBOOK: 'GET_SINGLE_LOGBOOK',

  getSingleLogbook: data => {
    return {
      type: actions.GET_SINGLE_LOGBOOK,
      data,
    };
  },
  addNotesMhs: data => {
    return {
      type: actions.ADD_NOTES_MHS,
      data,
    };
  },
  getLogbook: data => {
    return {
      type: actions.GET_LOGBOOK,
      data,
    };
  },

  getStudentLogbook: data => {
    return {
      type: actions.GET_STUDENT_LOGBOOK,
      data,
    };
  },

  setLogbook: data => {
    return {
      type: actions.SET_LOGBOOK,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_LOGBOOK,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_LOGBOOK,
    };
  },

  createLogbook: data => {
    return {
      type: actions.CREATE_LOGBOOK,
      data,
    };
  },

  deleteLogbook: data => {
    return {
      type: actions.DELETE_LOGBOOK,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingLogbook: data => {
    return {
      type: actions.SET_LOADING_LOGBOOK,
      data,
    };
  },
};

export default actions;
