const actions = {
  GET_KONTEN: 'GET_KONTEN',
  GET_SINGLE_KONTEN: 'GET_SINGLE_KONTEN',
  SET_KONTEN: 'SET_KONTEN',
  SET_SINGLE_KONTEN: 'SET_SINGLE_KONTEN',
  DELETE_KONTEN: 'DELETE_KONTEN',
  DELETE_SINGLE_KONTEN: 'DELETE_SINGLE_KONTEN',
  CREATE_KONTEN: 'CREATE_KONTEN',
  ERROR_MSG: 'ERROR_MSG_KONTEN',
  SHOW_MODAL_KONTEN: 'SHOW_MODAL_KONTEN',
  HIDE_MODAL_KONTEN: 'HIDE_MODAL_KONTEN',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING: 'SET_LOADING',

  getContent: data => {
    return {
      type: actions.GET_KONTEN,
      data,
    };
  },
  getSingleContent: data => {
    return {
      type: actions.GET_SINGLE_KONTEN,
      data,
    };
  },

  setContent: data => {
    return {
      type: actions.SET_KONTEN,
      data,
    };
  },

  setSingleContent: data => {
    return {
      type: actions.SET_SINGLE_KONTEN,
      data,
    };
  },

  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_KONTEN,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_KONTEN,
    };
  },

  createContent: data => {
    return {
      type: actions.CREATE_KONTEN,
      data,
    };
  },

  deleteContent: data => {
    return {
      type: actions.DELETE_KONTEN,
      data,
    };
  },
  deleteSingleContent: data => {
    return {
      type: actions.DELETE_SINGLE_KONTEN,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingContent: data => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
};

export default actions;
