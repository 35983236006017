const actions = {
  GET_SINGLE_EVALUATOR: 'GET_SINGLE_EVALUATOR',
  SET_SINGLE_EVALUATOR: 'SET_SINGLE_EVALUATOR',
  DELETE_SINGLE_EVALUATOR: 'DELETE_SINGLE_EVALUATOR',
  CREATE_SINGLE_EVALUATOR: 'CREATE_SINGLE_EVALUATOR',
  ERROR_MSG: 'ERROR_MSG_SINGLE_EVALUATOR',
  SHOW_MODAL_SINGLE_EVALUATOR: 'SHOW_MODAL_SINGLE_EVALUATOR',
  HIDE_MODAL_SINGLE_EVALUATOR: 'HIDE_MODAL_SINGLE_EVALUATOR',
  SET_LOADING_SINGLE_EVALUATOR: 'SET_LOADING_SINGLE_EVALUATOR',
  SET_PHOTO_EVALUATOR: 'SET_PHOTO_EVALUATOR',

  getSingleEvaluator: data => {
    return {
      type: actions.GET_SINGLE_EVALUATOR,
      data,
    };
  },

  setPhoto: data => {
    return {
      type: actions.SET_PHOTO_EVALUATOR,
      data,
    };
  },

  setEvaluator: data => {
    return {
      type: actions.SET_SINGLE_EVALUATOR,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_SINGLE_EVALUATOR,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_SINGLE_EVALUATOR,
    };
  },

  createEvaluator: data => {
    return {
      type: actions.CREATE_SINGLE_EVALUATOR,
      data,
    };
  },

  deleteEvaluator: data => {
    return {
      type: actions.DELETE_SINGLE_EVALUATOR,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingEvaluator: data => {
    return {
      type: actions.SET_LOADING_SINGLE_EVALUATOR,
      data,
    };
  },
};

export default actions;
