import React, {lazy, Suspense, useEffect, useState} from 'react';
import {message, Spin} from 'antd';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import AuthLayout from '../container/profile/authentication/Index';
import {checkAuth} from '../redux/authentication/actionCreator';
import {setItem} from '../utility/localStorageControl';

const Login = lazy(() => import('../container/profile/authentication/overview/SignIn'));

const NotFound = () => {
  return <Redirect to="/" />;
};

const HandleSuccessCallback = ({ match }) => {
  const { token, role } = match.params;
  setItem('access_token', token);
  setItem('role', role);
  const dispatch = useDispatch();
  const [firstTime] = useState(true);
  useEffect(() => {
    dispatch(checkAuth());
  }, [firstTime]);
  return <Login />;
};

const HandleFailedCallback = ({ match }) => {
  message.error(match.params.message);
  return <Login />;
};

const FrontendRoutes = () => {
  const dispatch = useDispatch();
  const [firstTime] = useState(true);
  useEffect(() => {
    dispatch(checkAuth());
  }, [firstTime]);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Suspense
          fallback={
            <div className="spin">
              <Spin />
            </div>
          }
        >
          <Route exact path="/" component={Login} />
          <Route exact path="/auth/callback/success/:token/:role" component={HandleSuccessCallback} />
          <Route exact path="/auth/callback/failed/:type/:message" component={HandleFailedCallback} />
          <Route exact path="*" component={NotFound} />
        </Suspense>
      </Switch>
    </Router>
  );
};

export default AuthLayout(FrontendRoutes);
