import React, { lazy, Suspense } from 'react'
import { Spin } from 'antd'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import Dashboard from './dashboard'
import withAdminLayout from '../../layout/withAdminLayout'

const News = lazy(() => import('../../container/admin/News'));

const Jadwal = lazy(() => import('../../container/admin/jadwal'));

const MahasiswaManage = lazy(() => import('./MahasiswaManage'));

const DplManage = lazy(() => import('./DplManage'));
const EvaluatorManage = lazy(() => import('./EvaluatorManage'));

const Nilai = lazy(() => import('../../container/admin/nilai'));
const Profile = lazy(() => import('../../container/profile/adminProfile'));

const Admin = () => {
  const { path } = useRouteMatch();
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route path={`${path}/mahasiswa`} component={MahasiswaManage} />
        <Route exact path={`${path}/news`} component={News} />
        <Route path={`${path}/dpl`} component={DplManage} />
        <Route path={`${path}/nilai`} component={Nilai} />
        <Route path={`${path}/jadwal`} component={Jadwal} />
        <Route path={`${path}/profile`} component={Profile} />
        <Route path={`${path}/evaluator`} component={EvaluatorManage} />
        <Route exact path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Admin);
