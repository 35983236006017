import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from '@my-components/buttons/buttons'
import { ErrorWrapper } from './style'
import { Main } from '../styled'
import Heading from '../../components/heading/heading'

const NotFound = () => {
  const { role } = useSelector(state => state.auth);
  return (
    <Main>
      <ErrorWrapper>
        <img src={require(`../../static/img/pages/404.svg`)} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>Maaf! Halaman yang anda akses tidak tersedia.</p>
        <NavLink to={`/${role || ''}`}>
          <Button size="default" type="primary" to={`/${role || ''}`}>
            Kembali ke Dashboard
          </Button>
        </NavLink>
      </ErrorWrapper>
    </Main>
  );
};

export default NotFound;
