const actions = {
  GET_MAHASISWA: 'GET_MAHASISWA',
  SET_MAHASISWA: 'SET_MAHASISWA',
  DELETE_MAHASISWA: 'DELETE_MAHASISWA',
  CREATE_MAHASISWA: 'CREATE_MAHASISWA',
  ERROR_MSG: 'ERROR_MSG_MAHASISWA',
  SHOW_MODAL_MAHASISWA: 'SHOW_MODAL_MAHASISWA',
  HIDE_MODAL_MAHASISWA: 'HIDE_MODAL_MAHASISWA',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING: 'SET_LOADING',
  GET_SINGLE_MAHASISWA: 'GET_SINGLE_MAHASISWA',

  getSingleMhs: data => {
    return {
      type: actions.GET_SINGLE_MAHASISWA,
      data,
    };
  },
  getMhs: data => {
    return {
      type: actions.GET_MAHASISWA,
      data,
    };
  },

  setMhs: data => {
    return {
      type: actions.SET_MAHASISWA,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_MAHASISWA,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_MAHASISWA,
    };
  },

  createMhs: data => {
    return {
      type: actions.CREATE_MAHASISWA,
      data,
    };
  },

  deleteMhs: data => {
    return {
      type: actions.DELETE_MAHASISWA,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingMhs: data => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
};

export default actions;
