import actions from './actions';

const { GET_SINGLE_SCORE, ADD_SINGLE_SCORE, SET_LOADING_SCORE } = actions;

const initialStateFilter = {
  score: null,
  loading: true,
};

const ScoreReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SINGLE_SCORE:
      return {
        ...state,
        score: data,
        loading: false,
      };
    case ADD_SINGLE_SCORE:
      return {
        ...state,
        score: data,
        loading: false,
      };
    case SET_LOADING_SCORE:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default ScoreReducers;
