const actions = {
  GET_EVALUATOR: 'GET_EVALUATOR',
  SET_EVALUATOR: 'SET_EVALUATOR',
  DELETE_EVALUATOR: 'DELETE_EVALUATOR',
  CREATE_EVALUATOR: 'CREATE_EVALUATOR',
  ERROR_MSG: 'ERROR_MSG_EVALUATOR',
  SHOW_MODAL_EVALUATOR: 'SHOW_MODAL_EVALUATOR',
  HIDE_MODAL_EVALUATOR: 'HIDE_MODAL_EVALUATOR',
  SET_LOADING: 'SET_LOADING',
  GET_SINGLE_EVALUATOR: 'GET_SINGLE_EVALUATOR',

  getSingleEvaluator: data => {
    return {
      type: actions.GET_SINGLE_EVALUATOR,
      data,
    };
  },
  getEvaluator: data => {
    return {
      type: actions.GET_EVALUATOR,
      data,
    };
  },

  setEvaluator: data => {
    return {
      type: actions.SET_EVALUATOR,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_EVALUATOR,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_EVALUATOR,
    };
  },

  createEvaluator: data => {
    return {
      type: actions.CREATE_EVALUATOR,
      data,
    };
  },

  deleteEvaluator: data => {
    return {
      type: actions.DELETE_EVALUATOR,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingEvaluator: data => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
};

export default actions;
