import moment from 'moment';
import actions from './actions';
import 'moment/locale/id';

const {
  GET_LOGBOOK,
  GET_STUDENT_LOGBOOK,
  SET_LOGBOOK,
  CREATE_LOGBOOK,
  DELETE_LOGBOOK,
  ERROR_MSG,
  SET_DATE,
  ADD_NOTES_MHS,
  SET_LOADING_LOGBOOK,
} = actions;

const initialStateFilter = {
  date: moment().locale('id'),
  data: [],
  notes: null,
  loading: true,
  student: {
    loading: false,
    error: null,
    posts: [],
    postLoading: false,
  },
};

// sample data student
// {
//   postId: 1,
//   from: 'Meyri Carles',
//   time: moment().format('YYYY-MM-DD HH:mm:ss').unix(),
//   start_logbook: moment().format('YYYY-MM-DD HH:mm:ss'),
//   end_logbook: moment()
//     .add('1', 'hours')
//     .format('YYYY-MM-DD HH:mm:ss'),
//   img: [],
//   notes:
//     'Nam malesuada dolor tellus pretium amet was hendrerit facilisi  tempor quis enim sed ornare there suspendisse sed orci neque ac sed aliquet risus faucibus in pretium molestee.',
// },

const LogbookMhsReducer = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_LOGBOOK:
      return {
        ...state,
        date: data.date,
        data: data.logbook.sort((a, b) => b.id_logbook - a.id_logbook),
        notes: data.notes,
        loading: false,
      };
    case GET_STUDENT_LOGBOOK:
      return {
        ...state,
        student: {
          ...state.student,
          posts: data,
        },
      };
    case CREATE_LOGBOOK:
      return {
        ...state,
        student: {
          ...state.student,
          posts: [data, ...state.student.posts],
        },
      };
    case SET_DATE:
      return {
        ...state,
        dataForm: data || null,
        modal: {
          ...state.modal,
          visible: true,
        },
      };
    case SET_LOGBOOK:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id_student === val.id_student) {
            return data;
          }
          return val;
        }),
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case DELETE_LOGBOOK:
      return {
        ...state,
        student: {
          ...state.student,
          posts: state.student.posts.filter(val => data.id_logbook !== val.id_logbook),
        },
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    case SET_LOADING_LOGBOOK:
      return {
        ...state,
        loading: data,
      };
    case ADD_NOTES_MHS:
      return {
        ...state,
        notes: data,
        loading: false,
      };
    default:
      return state;
  }
};

export default LogbookMhsReducer;
