const actions = {
  GET_JADWAL: 'GET_JADWAL',
  SET_JADWAL: 'SET_JADWAL',
  SET_LOADING_JADWAL: 'SET_LOADING_JADWAL',

  getJadwal: data => {
    return {
      type: actions.GET_JADWAL,
      data,
    };
  },
  setJadwal: data => {
    return {
      type: actions.SET_JADWAL,
      data,
    };
  },
  setLoading: data => {
    return {
      type: actions.SET_LOADING_JADWAL,
      data,
    };
  },
};

export default actions;
