import React, {lazy, Suspense} from 'react'
import {Spin} from 'antd'
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom'
import Dashboard from './dashboard'
import withAdminLayout from '../../layout/withAdminLayout'

const Team = lazy(() => import('../../container/evaluator/kelompok'));
const Laporan = lazy(() => import('../../container/dpl/laporan'));
const ProfileMahasiswa = lazy(() => import('../../container/profile/myProfile'));
const Profile = lazy(() => import('../../container/profile/dplProfile'));

const Admin = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route exact path={`${path}/kelompok`} component={Team} />
        <Route exact path={`${path}/dosen`} component={Team} />
        <Route exact path={`${path}/mahasiswa`} component={Team} />
        <Route path={`${path}/mahasiswa/:id`} component={ProfileMahasiswa} />
        <Route path={`${path}/profile`} component={Profile} />
        <Route exact path={`${path}/laporan`} component={Laporan} />
        <Route exact path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Admin);
