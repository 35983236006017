import Cookies from 'js-cookie';
import actions from './actions';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  SET_AUTH_PHOTO,
  SET_STATUS_STUDENT,
} = actions;

const initState = {
  login: Cookies.get('logedIn'),
  role: Cookies.get('role'),
  user: Cookies.get('user') ? JSON.parse(Cookies.get('user')) : {},
  loading: false,
  authPhoto: null,
  error: null,
  statusStudent: 0,
};

/**
 *
 * @todo impure state mutation/explaination
 */
const AuthReducer = (state = initState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: data.isLoggin,
        loading: false,
        role: data.role,
        user: data.user,
      };
    case LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        login: data,
        loading: false,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SET_AUTH_PHOTO:
      return {
        ...state,
        authPhoto: data,
      };
    case SET_STATUS_STUDENT:
      return {
        ...state,
        statusStudent: data,
      };
    default:
      return state;
  }
};
export default AuthReducer;
