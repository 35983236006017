const actions = {
  GET_PROKER: 'GET_PROKER',
  SET_PROKER: 'SET_PROKER',
  DELETE_PROKER: 'DELETE_PROKER',
  CREATE_PROKER: 'CREATE_PROKER',
  ERROR_MSG: 'ERROR_MSG_PROKER',
  SHOW_MODAL_PROKER: 'SHOW_MODAL_PROKER',
  HIDE_MODAL_PROKER: 'HIDE_MODAL_PROKER',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING_PROKER: 'SET_LOADING_PROKER',
  REVISE_PROKER: 'REVISE_PROKER',
  APPROVED_PROKER: 'APPROVED_PROKER',
  REJECTED_PROKER: 'REJECTED_PROKER',
  NEED_REVISE_PROKER: 'NEED_REVISE_PROKER',
  COMMENT_PROKER: 'COMMENT_PROKER',

  commentProkerAction: data => {
    return {
      type: actions.COMMENT_PROKER,
      data,
    };
  },
  approveProkerAction: data => {
    return {
      type: actions.APPROVED_PROKER,
      data,
    };
  },
  rejectProkerAction: data => {
    return {
      type: actions.REJECTED_PROKER,
      data,
    };
  },
  needRevisionProkerAction: data => {
    return {
      type: actions.NEED_REVISE_PROKER,
      data,
    };
  },
  getProker: data => {
    return {
      type: actions.GET_PROKER,
      data,
    };
  },

  setProker: data => {
    return {
      type: actions.SET_PROKER,
      data,
    };
  },
  reviseProker: data => {
    return {
      type: actions.REVISE_PROKER,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_PROKER,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_PROKER,
    };
  },

  createProker: data => {
    return {
      type: actions.CREATE_PROKER,
      data,
    };
  },

  deleteProker: data => {
    return {
      type: actions.DELETE_PROKER,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingProker: data => {
    return {
      type: actions.SET_LOADING_PROKER,
      data,
    };
  },
};

export default actions;
