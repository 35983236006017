import actions from './actions';

const {
  GET_GROUPS_EVALUATOR,
  SET_DPL_AVAILABLE,
  SET_EVALUATOR_AVAILABLE,
  CREATE_GROUP,
  SET_GROUP,
  DELETE_GROUP,
  ERROR_MSG,
  SHOW_MODAL_GROUP,
  HIDE_MODAL_GROUP,
  SUCCESS_MSG,
  FAIL_MESSAGE,
  SET_LOADING_GROUP,
  GET_SINGLE_GROUP,
  SET_LOADING_EVALUATOR_DPLS,
} = actions;

const initialStateFilter = {
  dplAvailable: [],
  evaluatorAvailable: [],
  dplLoading: true,
  evaluatorLoading: true,
  isLoading: true,
  groups: [],
  pagination: {
    current: 1,
    pageSize: 10,
    position: 0,
    total: 0,
  },
  message: null,
};

const EvaluatorGroups = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_GROUPS_EVALUATOR:
      return {
        ...state,
        groups: data.groups.sort((a, b) => b.id_evaluator - a.id_evaluator),
        pagination: data.pagination,
        isLoading: false,
      };
    case SET_DPL_AVAILABLE:
      return {
        ...state,
        dplAvailable: data,
        dplLoading: false,
      };
    case SET_EVALUATOR_AVAILABLE:
      return {
        ...state,
        evaluatorAvailable: data,
        evaluatorLoading: false,
      };
    case SET_LOADING_EVALUATOR_DPLS:
      return {
        ...state,
        ...data,
      };
    default:
      return state;
  }
};

export default EvaluatorGroups;
