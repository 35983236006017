import actions from './actions';

const {
  GET_MAHASISWA,
  SET_MAHASISWA,
  CREATE_MAHASISWA,
  DELETE_MAHASISWA,
  ERROR_MSG,
  SHOW_MODAL_MAHASISWA,
  HIDE_MODAL_MAHASISWA,
  SUCCESS_MSG,
  SET_LOADING,
  GET_SINGLE_MAHASISWA,
} = actions;

const initialStateFilter = {
  modal: {
    visible: false,
    type: 'add',
  },
  data: [],
  pagination: {
    current: 1,
    pageSize: 10,
    position: 0,
  },
  jurusan: [],
  fakultas: [],
  search: '',
  loading: true,
  message: null,
  dataForm: null,
  singleStudent: {},
};

const NewsReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_MAHASISWA:
      return {
        ...state,
        data: data.mahasiswa.sort((a, b) => b.id - a.id),
        pagination: {
          ...data.pagination,
          total: data.total,
        },
        loading: false,
        jurusan: data.jurusan,
        fakultas: data.fakultas,
      };
    case GET_SINGLE_MAHASISWA:
      return {
        ...state,
        singleStudent: data,
        loading: false,
      };
    case CREATE_MAHASISWA:
      return {
        ...state,
        data: [data, ...state.data],
        modal: {
          ...state.modal,
          visible: false,
        },
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        message: SUCCESS_MSG,
      };
    case HIDE_MODAL_MAHASISWA:
      return {
        ...state,
        dataForm: null,
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case SHOW_MODAL_MAHASISWA:
      return {
        ...state,
        dataForm: data || null,
        modal: {
          ...state.modal,
          visible: true,
        },
      };
    case SET_MAHASISWA:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id_student === val.id_student) {
            return data;
          }
          return val;
        }),
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case DELETE_MAHASISWA:
      return {
        ...state,
        visible: false,
        data: state.data.filter(val => data.id_student !== val.id_student),
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default NewsReducers;
