import React from 'react'
import { NavLink } from 'react-router-dom'
import { Button } from '@my-components/buttons/buttons'
import { useDispatch } from 'react-redux'
import { logOut } from '@my-redux/authentication/actionCreator'
import { ErrorWrapper } from './style'
import { Main } from '../styled'
import Heading from '../../components/heading/heading'

const NotFoundGroups = () => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(logOut());
  };

  return (
    <Main>
      <ErrorWrapper>
        <img src={require(`../../static/img/pages/no-group.svg`)} style={{ marginBottom: 0 }} alt="404" />
        <Heading className="error-text" as="h3">
          404
        </Heading>
        <p>Maaf! Anda belum memiliki kelompok, silahkan hubungi admin.</p>
        <NavLink to="/" onClick={logout}>
          <Button size="default" type="primary">
            Keluar
          </Button>
        </NavLink>
      </ErrorWrapper>
    </Main>
  );
};

export default NotFoundGroups;
