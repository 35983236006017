import actions from './actions';

const {
  SET_SINGLE_MAHASISWA,
  CREATE_SINGLE_MAHASISWA,
  DELETE_SINGLE_MAHASISWA,
  ERROR_MSG,
  SHOW_MODAL_SINGLE_MAHASISWA,
  HIDE_MODAL_SINGLE_MAHASISWA,
  SUCCESS_MSG,
  SET_LOADING_SINGLE_STD,
  GET_SINGLE_MAHASISWA,
  SET_PHOTO_STUDENT,
} = actions;

const initialStateFilter = {
  data: {},
  loading: true,
  skeletonPhoto: true,
  message: null,
};

const NewsReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SINGLE_MAHASISWA:
      return {
        ...state,
        data: { ...state.data, ...data },
        loading: false,
      };
    case CREATE_SINGLE_MAHASISWA:
      return {
        ...state,
        data: [data, ...state.data],
        modal: {
          ...state.modal,
          visible: false,
        },
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        message: SUCCESS_MSG,
      };
    case HIDE_MODAL_SINGLE_MAHASISWA:
      return {
        ...state,
        dataForm: null,
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case SHOW_MODAL_SINGLE_MAHASISWA:
      return {
        ...state,
        dataForm: data || null,
        modal: {
          ...state.modal,
          visible: true,
        },
      };
    case SET_SINGLE_MAHASISWA:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id_student === val.id_student) {
            return data;
          }
          return val;
        }),
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case DELETE_SINGLE_MAHASISWA:
      return {
        ...state,
        visible: false,
        data: state.data.filter(val => data.id_student !== val.id_student),
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    case SET_LOADING_SINGLE_STD:
      return {
        ...state,
        loading: data,
      };
    case SET_PHOTO_STUDENT:
      return {
        ...state,
        data: { ...state.data, profile_picture: data },
        skeletonPhoto: false,
      };
    default:
      return state;
  }
};

export default NewsReducers;
