import React from 'react'
import { Menu } from 'antd'
import { useSelector } from 'react-redux'
import { NavLink, useRouteMatch } from 'react-router-dom'
import FeatherIcon from 'feather-icons-react'
import propTypes from 'prop-types'

import menuConfig from '../config/menu'

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();
  const auth = useSelector(state => state.auth);

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const getMenu = () => {
    if (auth.role === 'admin') {
      return menuConfig.admin;
    }
    if (auth.role === 'mahasiswa') {
      return menuConfig.mahasiswa;
    }
    if (auth.role === 'evaluator') {
      return menuConfig.evaluator;
    }
    return menuConfig.dpl;
  };

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >
      {getMenu().map((val, index) => {
        let menu;
        switch (val.type) {
          case 'sub_menu':
            menu = (
              <SubMenu key={index} icon={!topMenu && <FeatherIcon icon={val.icon} />} title={val.title}>
                {val.menu.map((val2, i) => {
                  return (
                    <Menu.Item key={`${i}${index}`}>
                      <NavLink onClick={toggleCollapsed} to={`${path}${val2.path}`}>
                        {val2.title}
                      </NavLink>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
            break;
          case 'single_menu':
            menu =
              val.path === '/signout' ? (
                <Menu.Item key={index} icon={<FeatherIcon icon={val.icon} />}>
                  <NavLink onClick={toggleCollapsed} to={val.path}>
                    {val.title}
                  </NavLink>
                </Menu.Item>
              ) : (
                <Menu.Item key={index} icon={<FeatherIcon icon={val.icon} />}>
                  <NavLink onClick={toggleCollapsed} to={`${path}${val.path}`}>
                    {val.title}
                  </NavLink>
                </Menu.Item>
              );
            break;
          default:
            menu = !topMenu && (
              <Menu.Item key={index}>
                <p className="sidebar-nav-title">{val.title}</p>
              </Menu.Item>
            );
            break;
        }
        return menu;
      })}
    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
