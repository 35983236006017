const actions = {
  GET_DPL_REPORT: 'GET_DPL_REPORT',
  SET_DPL_REPORT: 'SET_DPL_REPORT',
  DELETE_DPL_REPORT: 'DELETE_DPL_REPORT',
  CREATE_DPL_REPORT: 'CREATE_DPL_REPORT',
  ERROR_MSG: 'ERROR_MSG_DPL_REPORT',
  SHOW_MODAL_DPL_REPORT: 'SHOW_MODAL_DPL_REPORT',
  HIDE_MODAL_DPL_REPORT: 'HIDE_MODAL_DPL_REPORT',
  SUCCESS_MSG: 'Berhasil menambahkan Laporan',
  FAIL_MESSAGE: 'Gagal menambahkan Laporan',
  SET_LOADING: 'SET_LOADING',
  GET_SINGLE_DPL_REPORT: 'GET_SINGLE_DPL_REPORT',

  getSingleReport: data => {
    return {
      type: actions.GET_SINGLE_DPL_REPORT,
      data,
    };
  },
  getReport: data => {
    return {
      type: actions.GET_DPL_REPORT,
      data,
    };
  },

  setReport: data => {
    return {
      type: actions.SET_DPL_REPORT,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_DPL_REPORT,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_DPL_REPORT,
    };
  },

  createReport: data => {
    return {
      type: actions.CREATE_DPL_REPORT,
      data,
    };
  },

  deleteReport: data => {
    return {
      type: actions.DELETE_DPL_REPORT,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingReport: data => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
};

export default actions;
