const actions = {
  GET_GROUPS_EVALUATOR: 'GET_GROUPS_EVALUATOR',
  SET_DPL_AVAILABLE: 'SET_DPL_AVAILABLE',
  SET_EVALUATOR_AVAILABLE: 'SET_EVALUATOR_AVAILABLE',
  CREATE_GROUP: 'CREATE_GROUP_EVALUATOR',
  DELETE_GROUP: 'DELETE_GROUP_EVALUATOR',
  ERROR_MSG: 'ERROR_MSG_GROUP_EVALUATOR',
  SHOW_MODAL_GROUP: 'SHOW_MODAL_GROUP_EVALUATOR',
  HIDE_MODAL_GROUP: 'HIDE_MODAL_GROUP_EVALUATOR',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING_GROUP: 'SET_LOADING_GROUP_EVALUATOR',
  GET_SINGLE_GROUP: 'GET_SINGLE_GROUP_EVALUATOR',
  SET_LOADING_EVALUATOR_DPLS: 'SET_LOADING_EVALUATOR_DPLS',
  getGroupsEvaluator: data => {
    return {
      type: actions.GET_GROUPS_EVALUATOR,
      data,
    };
  },
  setEvaluatorAvailable: data => {
    return {
      type: actions.SET_EVALUATOR_AVAILABLE,
      data,
    };
  },
  setDplAvailable: data => {
    return {
      type: actions.SET_DPL_AVAILABLE,
      data,
    };
  },
  createGroupsEvaluator: data => {
    return {
      type: actions.CREATE_GROUP,
      data,
    };
  },
  setGroupEvaluator: data => {
    return {
      type: actions.SET_GROUP,
      data,
    };
  },
  deleteGroupEvaluator: data => {
    return {
      type: actions.DELETE_GROUP,
      data,
    };
  },
  setErrorMessage: data => {
    return {
      type: actions.ERROR_MSG,
      data,
    };
  },
  showModalGroup: data => {
    return {
      type: actions.SHOW_MODAL_GROUP,
      data,
    };
  },
  hideModalGroup: data => {
    return {
      type: actions.HIDE_MODAL_GROUP,
      data,
    };
  },
  setSuccessMassage: data => {
    return {
      type: actions.SUCCESS_MSG,
      data,
    };
  },
  setFailMessage: data => {
    return {
      type: actions.FAIL_MESSAGE,
      data,
    };
  },
  setLoadingGroup: data => {
    return {
      type: actions.SET_LOADING_GROUP,
      data,
    };
  },
  setLoadingDpls: data => {
    return {
      type: actions.SET_LOADING_EVALUATOR_DPLS,
      data,
    };
  },
  getSingleGroup: data => {
    return {
      type: actions.GET_SINGLE_GROUP,
      data,
    };
  },
};

export default actions;
