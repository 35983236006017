const actions = {
  GET_SINGLE_MAHASISWA: 'GET_SINGLE_MAHASISWA',
  SET_SINGLE_MAHASISWA: 'SET_SINGLE_MAHASISWA',
  DELETE_SINGLE_MAHASISWA: 'DELETE_SINGLE_MAHASISWA',
  CREATE_SINGLE_MAHASISWA: 'CREATE_SINGLE_MAHASISWA',
  ERROR_MSG: 'ERROR_MSG_SINGLE_MAHASISWA',
  SHOW_MODAL_SINGLE_MAHASISWA: 'SHOW_MODAL_SINGLE_MAHASISWA',
  HIDE_MODAL_SINGLE_MAHASISWA: 'HIDE_MODAL_SINGLE_MAHASISWA',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING_SINGLE_STD: 'SET_LOADING_SINGLE_STD',
  SET_PHOTO_STUDENT: 'SET_PHOTO_STUDENT',

  getSingleMhs: data => {
    return {
      type: actions.GET_SINGLE_MAHASISWA,
      data,
    };
  },

  setPhoto: data => {
    return {
      type: actions.SET_PHOTO_STUDENT,
      data,
    };
  },

  setMhs: data => {
    return {
      type: actions.SET_SINGLE_MAHASISWA,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_SINGLE_MAHASISWA,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_SINGLE_MAHASISWA,
    };
  },

  createMhs: data => {
    return {
      type: actions.CREATE_SINGLE_MAHASISWA,
      data,
    };
  },

  deleteMhs: data => {
    return {
      type: actions.DELETE_SINGLE_MAHASISWA,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingMhs: data => {
    return {
      type: actions.SET_LOADING_SINGLE_STD,
      data,
    };
  },
};

export default actions;
