const actions = {
  GET_GROUP: 'GET_GROUP',
  GET_GROUPS: 'GET_GROUPS',
  SET_GROUP: 'SET_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  CREATE_GROUP: 'CREATE_GROUP',
  ERROR_MSG: 'ERROR_MSG_GROUP',
  SHOW_MODAL_GROUP: 'SHOW_MODAL_GROUP',
  HIDE_MODAL_GROUP: 'HIDE_MODAL_GROUP',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING_GROUP: 'SET_LOADING_GROUP',
  GET_SINGLE_GROUP: 'GET_SINGLE_GROUP',
  GET_STUDENTS_GROUP: 'GET_STUDENTS_GROUP',
  GET_DPL_GROUP: 'GET_DPL_GROUP',
  LOAD_DATA_GROUP: 'LOAD_DATA_GROUP',
  SET_CURRENT_STUDENT_GROUP: 'SET_CURRENT_STUDENT_GROUP',

  setCurrentStudentGroup: data => {
    return {
      type: actions.SET_CURRENT_STUDENT_GROUP,
      data,
    };
  },
  studentGroup: data => {
    return {
      type: actions.GET_STUDENTS_GROUP,
      data,
    };
  },
  dplGroup: data => {
    return {
      type: actions.GET_DPL_GROUP,
      data,
    };
  },
  getSingleGroup: data => {
    return {
      type: actions.GET_SINGLE_GROUP,
      data,
    };
  },
  getGroup: data => {
    return {
      type: actions.GET_GROUP,
      data,
    };
  },
  getGroupDpl: data => {
    return {
      type: actions.GET_GROUPS,
      data,
    };
  },

  setGroup: data => {
    return {
      type: actions.SET_GROUP,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_GROUP,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_GROUP,
    };
  },

  createGroup: data => {
    return {
      type: actions.CREATE_GROUP,
      data,
    };
  },

  deleteGroup: data => {
    return {
      type: actions.DELETE_GROUP,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  showsLoading: data => {
    return {
      type: actions.SET_LOADING_GROUP,
      data,
    };
  },
  loadDataGroup: data => {
    return {
      type: actions.LOAD_DATA_GROUP,
      data,
    };
  },
};

export default actions;
