import { darkTheme, theme } from './theme/themeVariables';

const config = {
  darkMode: false,
  topMenu: false,
  rtl: false,
  theme,
  darkTheme,
};

export default config;
