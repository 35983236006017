import axios from 'axios';
import {getItem} from '../../utility/localStorageControl';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const authHeader = () => ({
  Authorization: `Bearer ${getItem('access_token')}`,
  'Cache-Control': 'no-cache',
});

const client = axios.create({
  baseURL: API_ENDPOINT,
  headers: {
    Authorization: `Bearer ${getItem('access_token')}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

class DataService {
  static get(path = '') {
    return client({
      method: 'GET',
      url: path,
      headers: { ...authHeader() },
    });
  }

  static getData(path = '', data = {}) {
    return client({
      method: 'GET',
      url: path,
      data,
      headers: { ...authHeader() },
    });
  }

  static post(path = '', data = {}, optionalHeader = {}) {
    return client({
      method: 'POST',
      url: path,
      data,
      headers: { ...authHeader(), ...optionalHeader },
    }).then(response => {
      const { schedule, code } = response.data;
      if (code === 403) {
        return {
          status: 403,
          schedule,
        };
      }
      return response;
    });
  }

  static patch(path = '', data = {}) {
    return client({
      method: 'PATCH',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    });
  }

  static put(path = '', data = {}) {
    return client({
      method: 'PUT',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    }).then(response => {
      const { schedule, code } = response.data;
      if (code === 403) {
        return {
          status: 403,
          schedule,
        };
      }
      return response;
    });
  }

  static delete(path = '', data = {}) {
    return client({
      method: 'DELETE',
      url: path,
      data: JSON.stringify(data),
      headers: { ...authHeader() },
    }).then(response => {
      const { schedule, code } = response.data;
      if (code === 403) {
        return {
          status: 403,
          schedule,
        };
      }
      return response;
    });
  }

  static download(path = '', data = { attachment: 'file' }) {
    client({
      method: 'GET',
      url: path,
      responseType: 'blob',
      headers: { ...authHeader() },
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', data.attachment);
      document.body.appendChild(link);
      link.click();
    });
  }

  static file(path = '', data = { attachment: 'file' }) {
    return client({
      method: 'GET',
      url: path,
      responseType: 'blob',
      headers: { ...authHeader(), 'Cache-Control': 'no-cache' },
    }).then(response => {
      const { type } = response.data;
      const extension = type.split('/').pop();
      if (extension === 'json') {
        return null;
      }
      const theBlob = response.data;
      return new File([theBlob], `${data.attachment}.${extension}`, { type });
    });
  }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
  // do something before executing the request
  // For example tag along the bearer access token to request header or set a cookie
  const requestConfig = config;
  const { headers } = config;
  requestConfig.headers = { ...headers, Authorization: `Bearer ${getItem('access_token')}` };

  return requestConfig;
});

client.interceptors.response.use(
  response => response,
  error => {
    /**
     * Do something in case the response returns an error code [3**, 4**, 5**] etc
     * For example, on token expiration retrieve a new access token, retry a failed request etc
     */
    const { response } = error;
    if (response) {
      if (response.status === 500) {
        // do something here
      } else {
        return response;
      }
    }
    return Promise.reject(error);
  },
);
export { DataService };
