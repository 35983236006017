import Cookies from 'js-cookie';
import actions from './actions';
import {DataService} from '../../config/dataService/dataService';
import {getItem, removeItem, setItem} from '../../utility/localStorageControl';

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  setAuthPhoto,
  setStatusStudent,
} = actions;

const getStatusStudentReq = () => {
  return async dispatch => {
    try {
      const { data } = await DataService.get('/api/group/mahasiswa');
      if (data.code === 200) {
        dispatch(setStatusStudent(true));
      } else {
        dispatch(setStatusStudent(false));
      }
    } catch (e) {
      dispatch(loginErr(e));
    }
  };
};

const checkAuth = () => {
  return async dispatch => {
    let path = '';
    const role = getItem('role');
    switch (role) {
      case 'mahasiswa':
        path = '/api/token/mahasiswa';
        break;
      case 'dpl':
        path = '/api/token/dpl';
        break;
      case 'admin':
        path = '/api/token/admin';
        break;
      case 'evaluator':
        path = '/api/token/evaluator';
        break;
      default:
        return;
    }
    const response = await DataService.get(path);
    if (response.status === 200) {
      const { data } = response;
      Cookies.set('logedIn', true);
      Cookies.set('role', role);
      Cookies.set('user', response.data.user);
      dispatch(loginSuccess({ isLoggin: true, role, user: data.user }));
    } else {
      logOut();
    }
  };
};

const login = data => {
  return async dispatch => {
    try {
      dispatch(loginBegin());
      const service = await DataService.post('/api/login', data);
      const response = service.data;
      if (response.code === '200') {
        setItem('access_token', response.token);
        setItem('role', response.role);
        dispatch(checkAuth());
        // Cookies.set('logedIn', true);
        // Cookies.set('role', response.role);
        // Cookies.set('user', response.data);
        // dispatch(loginSuccess({ isLoggin: true, role: response.role, user: response.data }));
      } else {
        dispatch(loginErr('username atau password salah'));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const redirectToGoogle = () => {
  return async dispatch => {
    try {
      window.location.href = 'http://localhost/API-KPMDR/public/auth/google';
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const handleGoogleCallback = callbackParams => {
  return async dispatch => {
    try {
      const res = await DataService.get(`/auth/google/callback${callbackParams}`);
      console.log(res);
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const logOut = () => {
  return async dispatch => {
    try {
      await DataService.get('/api/logout');
      removeItem('access_token');
      removeItem('role');
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      Cookies.remove('role');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const getProfilePhotoAuth = data => {
  return async dispatch => {
    let file = null;
    switch (data.role) {
      case 'mahasiswa':
        file = await DataService.file(`/api/profile/mahasiswa/getProfilePhoto/${data.user.id_student}`);
        break;
      case 'dpl':
        file = await DataService.file(`/api/profile/dpl/getProfilePhoto/${data.user.id_dpl}`);
        break;
      default:
    }
    let imageUrl = null;
    if (file) {
      const urlCreator = window.URL || window.webkitURL;
      imageUrl = urlCreator.createObjectURL(file);
    }
    dispatch(setAuthPhoto(imageUrl));
  };
};

const deleteProfilePhotoAuth = () => {
  return async dispatch => {
    dispatch(setAuthPhoto(null));
  };
};

export {
  getStatusStudentReq,
  login,
  logOut,
  getProfilePhotoAuth,
  deleteProfilePhotoAuth,
  checkAuth,
  redirectToGoogle,
  handleGoogleCallback,
};
