import actions from './actions';

const {
  GET_KONTEN,
  GET_SINGLE_KONTEN,
  SET_KONTEN,
  SET_SINGLE_KONTEN,
  CREATE_KONTEN,
  DELETE_KONTEN,
  DELETE_SINGLE_KONTEN,
  ERROR_MSG,
  SHOW_MODAL_KONTEN,
  HIDE_MODAL_KONTEN,
  SET_LOADING,
} = actions;

const initialStateFilter = {
  modal: {
    visible: false,
  },
  data: [],
  pagination: {
    current: 1,
    pageSize: 10,
    position: 0,
  },
  search: '',
  loading: true,
  message: null,
  singleData: null,
};

const NewsReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_KONTEN:
      return {
        ...state,
        data: data.konten.sort((a, b) => b.id - a.id),
        pagination: {
          ...data.pagination,
          total: data.total,
        },
        loading: false,
      };
    case GET_SINGLE_KONTEN:
      return {
        ...state,
        singleData: data,
        loading: false,
      };
    case CREATE_KONTEN:
      return {
        ...state,
        singleData: data,
      };
    case HIDE_MODAL_KONTEN:
      return {
        ...state,
        modal: {
          visible: false,
        },
      };
    case SHOW_MODAL_KONTEN:
      return {
        ...state,
        modal: {
          visible: true,
        },
      };
    case SET_KONTEN:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id_student === val.id_student) {
            return data;
          }
          return val;
        }),
        modal: {
          ...state.modal,
          visible: false,
        },
      };

    case SET_SINGLE_KONTEN:
      return {
        ...state,
        singleData: {
          ...data,
          attachment: data.attachment ? data.attachment.name : data.just_delete ? null : state.singleData.attachment,
        },
      };
    case DELETE_KONTEN:
      return {
        ...state,
        visible: false,
        data: state.data.filter(val => data.id_student !== val.id_student),
      };
    case DELETE_SINGLE_KONTEN:
      return {
        ...state,
        visible: false,
        singleData: null,
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default NewsReducers;
