import { message } from 'antd'
import { serialize } from 'object-to-formdata'
import moment from 'moment'
import actions from './actions'
import { DataService } from '../../config/dataService/dataService'

const { getJadwal, setJadwal, setLoading } = actions;

const getJadwalRequest = () => {
  return async dispatch => {
    try {
      const service = await DataService.get(`/api/jadwal/get`);
      const response = service.data;
      const { schedule } = response;
      const date = {
        date: [moment(schedule.start), moment(schedule.end)],
        dateString: [moment(schedule.start).format('YYYY-MM-DD'), moment(schedule.end).format('YYYY-MM-DD')],
        period: schedule.period,
        academicYear: schedule.academic_year,
        appraisal: [schedule.start_appraisal, schedule.end_appraisal],
        report: [schedule.start_report, schedule.end_report],
      };
      if (service.status === 200) {
        dispatch(getJadwal(date));
      } else {
        window.location.href = '/404';
      }
    } catch (e) {
      console.log(e);
    }
  };
};

const setJadwalRequest = date => {
  return async dispatch => {
    try {
      const data = {
        start: date.dateString ? date.dateString[0] : null,
        end: date.dateString ? date.dateString[1] : null,
        academic_year: date.academicYear || null,
        period: date.period || null,
        start_appraisal: date.appraisal ? date.appraisal[0] : null,
        end_appraisal: date.appraisal ? date.appraisal[1] : null,
        start_report: date.report ? date.report[0] : null,
        end_report: date.report ? date.report[1] : null,
      };
      const service = await DataService.post(`/api/jadwal/set`, serialize(data));
      if (service.status === 200) {
        dispatch(setJadwal(date));
        message.success('Berhasil memperbarui jadwal');
      } else {
        message.error('Gagal memperbarui jadwal');
      }
    } catch (e) {
      message.error('Gagal memperbarui jadwal');
      console.log(e);
    }
  };
};

const setLoadingJadwal = status => {
  return async dispatch => {
    dispatch(setLoading(status));
  };
};

export { setLoadingJadwal, getJadwalRequest, setJadwalRequest };
