const actions = {
  GET_DPL: 'GET_DPL',
  SET_DPL: 'SET_DPL',
  DELETE_DPL: 'DELETE_DPL',
  CREATE_DPL: 'CREATE_DPL',
  ERROR_MSG: 'ERROR_MSG_DPL',
  SHOW_MODAL_DPL: 'SHOW_MODAL_DPL',
  HIDE_MODAL_DPL: 'HIDE_MODAL_DPL',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING: 'SET_LOADING',
  GET_SINGLE_DPL: 'GET_SINGLE_DPL',

  getSingleDpl: data => {
    return {
      type: actions.GET_SINGLE_DPL,
      data,
    };
  },
  getDpl: data => {
    return {
      type: actions.GET_DPL,
      data,
    };
  },

  setDpl: data => {
    return {
      type: actions.SET_DPL,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_DPL,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_DPL,
    };
  },

  createDpl: data => {
    return {
      type: actions.CREATE_DPL,
      data,
    };
  },

  deleteDpl: data => {
    return {
      type: actions.DELETE_DPL,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingDpl: data => {
    return {
      type: actions.SET_LOADING,
      data,
    };
  },
};

export default actions;
