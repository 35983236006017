import actions from './actions';

const {
  GET_NEWS,
  SET_NEWS,
  CREATE_NEWS,
  DELETE_NEWS,
  ERROR_MSG,
  SHOW_MODAL_NEWS,
  HIDE_MODAL_NEWS,
  SUCCESS_MSG,
} = actions;

const initialStateFilter = {
  loading: true,
  visible: false,
  message: null,
  error: null,
  dataForm: null,
  data: [],
};

// Format Data
// {
//   id: "",
//   title: "",
//   description: "",
//   role: "",
//   attachment: ""
// }

const NewsReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_NEWS:
      return {
        ...state,
        data: data.sort((a, b) => b.id - a.id),
        loading: false,
      };
    case CREATE_NEWS:
      return {
        ...state,
        visible: false,
        data: [data, ...state.data],
        message: SUCCESS_MSG,
      };
    case HIDE_MODAL_NEWS:
      return {
        ...state,
        dataForm: null,
        visible: false,
      };
    case SHOW_MODAL_NEWS:
      return {
        ...state,
        dataForm: data || null,
        visible: true,
      };
    case SET_NEWS:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id === val.id) {
            if (!data.attachment) {
              data.attachment = val.attachment;
            }
            return data;
          }
          return val;
        }),
        visible: false,
      };
    case DELETE_NEWS:
      return {
        ...state,
        visible: false,
        data: state.data.filter(val => data.id !== val.id),
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default NewsReducers;
