import actions from './actions';

const {
  SET_SINGLE_DPL,
  CREATE_SINGLE_DPL,
  DELETE_SINGLE_DPL,
  ERROR_MSG,
  SHOW_MODAL_SINGLE_DPL,
  HIDE_MODAL_SINGLE_DPL,
  SUCCESS_MSG,
  SET_LOADING_SINGLE_DPL,
  GET_SINGLE_DPL,
  SET_PHOTO_STUDENT,
} = actions;

const initialStateFilter = {
  data: {},
  loading: true,
  skeletonPhoto: true,
  message: null,
};

const SingleDplReducers = (state = initialStateFilter, action) => {
  const { type, data, err } = action;
  switch (type) {
    case GET_SINGLE_DPL:
      return {
        ...state,
        data: { ...state.data, ...data },
        loading: false,
      };
    case CREATE_SINGLE_DPL:
      return {
        ...state,
        data: [data, ...state.data],
        modal: {
          ...state.modal,
          visible: false,
        },
        pagination: {
          ...state.pagination,
          total: state.pagination.total + 1,
        },
        message: SUCCESS_MSG,
      };
    case HIDE_MODAL_SINGLE_DPL:
      return {
        ...state,
        dataForm: null,
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case SHOW_MODAL_SINGLE_DPL:
      return {
        ...state,
        dataForm: data || null,
        modal: {
          ...state.modal,
          visible: true,
        },
      };
    case SET_SINGLE_DPL:
      return {
        ...state,
        data: state.data.map(val => {
          if (data.id_dpl === val.id_dpl) {
            return data;
          }
          return val;
        }),
        modal: {
          ...state.modal,
          visible: false,
        },
      };
    case DELETE_SINGLE_DPL:
      return {
        ...state,
        visible: false,
        data: state.data.filter(val => data.id_dpl !== val.id_dpl),
      };
    case ERROR_MSG:
      return {
        ...state,
        error: err,
        visible: false,
        loading: false,
      };
    case SET_LOADING_SINGLE_DPL:
      return {
        ...state,
        loading: data,
      };
    case SET_PHOTO_STUDENT:
      return {
        ...state,
        data: { ...state.data, profile_picture: data },
        skeletonPhoto: false,
      };
    default:
      return state;
  }
};

export default SingleDplReducers;
