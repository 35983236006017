const actions = {
  GET_SINGLE_DPL: 'GET_SINGLE_DPL',
  SET_SINGLE_DPL: 'SET_SINGLE_DPL',
  DELETE_SINGLE_DPL: 'DELETE_SINGLE_DPL',
  CREATE_SINGLE_DPL: 'CREATE_SINGLE_DPL',
  ERROR_MSG: 'ERROR_MSG_SINGLE_DPL',
  SHOW_MODAL_SINGLE_DPL: 'SHOW_MODAL_SINGLE_DPL',
  HIDE_MODAL_SINGLE_DPL: 'HIDE_MODAL_SINGLE_DPL',
  SUCCESS_MSG: 'Berhasil menambahkan berita',
  FAIL_MESSAGE: 'Gagal menambahkan berita',
  SET_LOADING_SINGLE_DPL: 'SET_LOADING_SINGLE_DPL',
  SET_PHOTO_STUDENT: 'SET_PHOTO_STUDENT',

  getSingleDpl: data => {
    return {
      type: actions.GET_SINGLE_DPL,
      data,
    };
  },

  setPhoto: data => {
    return {
      type: actions.SET_PHOTO_STUDENT,
      data,
    };
  },

  setDpl: data => {
    return {
      type: actions.SET_SINGLE_DPL,
      data,
    };
  },
  showModal: (data = false) => {
    return {
      type: actions.SHOW_MODAL_SINGLE_DPL,
      data,
    };
  },
  hideModal: () => {
    return {
      type: actions.HIDE_MODAL_SINGLE_DPL,
    };
  },

  createDpl: data => {
    return {
      type: actions.CREATE_SINGLE_DPL,
      data,
    };
  },

  deleteDpl: data => {
    return {
      type: actions.DELETE_SINGLE_DPL,
      data,
    };
  },
  errorMessage: err => {
    return {
      type: actions.ERROR_MSG,
      err,
    };
  },
  setLoadingDpl: data => {
    return {
      type: actions.SET_LOADING_SINGLE_DPL,
      data,
    };
  },
};

export default actions;
