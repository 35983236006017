import {combineReducers} from 'redux';
import themeUsersReducer from './themeUsers/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import {teamReducer} from './team/reducers';
import {userGroupReducer, userReducer} from './users/reducers';
import {projectReducer, SingleProjectReducer} from './project/reducers';
import Todo from './todo/reducers';
import Note from './note/reducers';
import Profile from './profile/reducers';
// Custom reducers
import News from './news/reducers';
import Mahasiswa from './mahasiswa/reducers';
import Dpl from './dpl/reducers';
import SingleMahasiswa from './singleMahasiswa/reducers';
import SingleDpl from './singleDpl/reducers';
import Proker from './proker/reducers';
import Konten from './konten/reducers';
import LogbookMhs from './logbook_mhs/reducers';
import LogbookDpl from './logbook_dpl/reducers';
import Group from './group/reducers';
import StudentReport from './studentReport/reducers';
import dplReport from './dplReport/reducers';
import Jadwal from './jadwal/reducers';
import score from './score/reducers';
import evaluator from './evaluator/reducers';
import singleEvaluator from './singleEvaluator/reducers';
import evaluatorGroup from './evaluator_group/reducers';

const rootReducers = combineReducers({
  themeUsers: themeUsersReducer,
  users: userReducer,
  userGroup: userGroupReducer,
  team: teamReducer,
  auth: authReducer,
  projects: projectReducer,
  project: SingleProjectReducer,
  ChangeLayoutMode,
  Todo,
  Note,
  Profile,
  // Custom reducers
  news: News,
  mahasiswa: Mahasiswa,
  singleMahasiswa: SingleMahasiswa,
  singleDpl: SingleDpl,
  proker: Proker,
  konten: Konten,
  logbookMhs: LogbookMhs,
  logbookDpl: LogbookDpl,
  dpl: Dpl,
  groups: Group,
  studentReport: StudentReport,
  dplReport,
  jadwal: Jadwal,
  score,
  evaluator,
  singleEvaluator,
  evaluatorGroup,
});

export default rootReducers;
