const actions = {
  GET_SINGLE_SCORE: 'GET_SINGLE_SCORE',
  ADD_SINGLE_SCORE: 'ADD_SINGLE_SCORE',
  SET_LOADING_SCORE: 'SET_LOADING_SCORE',
  getSingle: data => {
    return {
      type: actions.GET_SINGLE_SCORE,
      data,
    };
  },
  addScore: data => {
    return {
      type: actions.ADD_SINGLE_SCORE,
      data,
    };
  },
  setLoading: data => {
    return {
      type: actions.SET_LOADING_SCORE,
      data,
    };
  },
};

export default actions;
