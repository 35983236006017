import moment from 'moment';
import actions from './actions';

const { GET_JADWAL, SET_JADWAL, SET_LOADING_JADWAL } = actions;

const initialStateFilter = {
  date: [moment(), moment().add(1, 'months')],
  dateString: [moment().format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')],
  academicYear: `${moment()
    .add(-1, 'years')
    .format('YYYY')}/${moment().format('YYYY')}`,
  period: 'ganjil',
  appraisal: [moment().format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')],
  report: [moment().format('DD/MM/YYYY'), moment().format('DD/MM/YYYY')],
  loading: true,
};

const DplReducers = (state = initialStateFilter, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_JADWAL:
      return {
        ...state,
        loading: false,
        ...data,
      };
    case SET_JADWAL:
      return {
        ...state,
        loading: false,
        date: data.date || state.date,
        dateString: data.dateString || state.dateString,
        academicYear: data.academicYear || state.academicYear,
        period: data.period || state.period,
        appraisal: data.appraisal || state.appraisal,
        report: data.report || state.report,
      };
    case SET_LOADING_JADWAL:
      return {
        ...state,
        loading: data,
      };
    default:
      return state;
  }
};

export default DplReducers;
