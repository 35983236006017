const config = {
  admin: [
    {
      type: 'nav_title',
      title: 'Menu Utama',
    },
    {
      type: 'sub_menu',
      title: 'Dashboard',
      icon: 'home', // use FeatherIcon,
      menu: [
        {
          type: 'menu',
          title: 'Dashboard',
          path: '/',
        },
        {
          type: 'menu',
          title: 'Berita',
          path: '/news',
        },
      ],
    },
    {
      type: 'sub_menu',
      title: 'Mahasiswa',
      icon: 'users', // use FeatherIcon,
      menu: [
        {
          type: 'menu',
          title: 'Daftar Mahasiswa',
          path: '/mahasiswa',
        },
        {
          type: 'menu',
          title: 'Program Kerja',
          path: '/mahasiswa/proker',
        },
      ],
    },
    {
      type: 'sub_menu',
      title: 'Dosen',
      icon: 'feather', // use FeatherIcon,
      menu: [
        {
          type: 'menu',
          title: 'Daftar DPL',
          path: '/dpl',
        },
        {
          type: 'menu',
          title: 'Kelompok',
          path: '/dpl/kelompok',
        },
      ],
    },
    {
      type: 'sub_menu',
      title: 'Evaluator',
      icon: 'message-circle', // use FeatherIcon,
      menu: [
        {
          type: 'menu',
          title: 'Daftar Evaluator',
          path: '/evaluator',
        },
        {
          type: 'menu',
          title: 'Kelompok',
          path: '/evaluator/kelompok',
        },
      ],
    },
    {
      type: 'nav_title',
      title: 'Menu Tambahan',
    },
    {
      type: 'single_menu',
      title: 'Nilai',
      icon: 'star', // use FeatherIcon,
      path: '/nilai',
    },
    {
      type: 'single_menu',
      title: 'Jadwal',
      icon: 'calendar', // use FeatherIcon,
      path: '/jadwal',
    },
    {
      type: 'single_menu',
      title: 'Sign Out',
      icon: 'log-out', // use FeatherIcon,
      path: '/signout',
    },
  ],
  mahasiswa: [
    {
      type: 'nav_title',
      title: 'Menu Utama',
    },
    {
      type: 'single_menu',
      title: 'Dashboard',
      icon: 'home', // use FeatherIcon,
      path: '/',
    },
    {
      type: 'single_menu',
      title: 'Program kerja',
      icon: 'check-square', // use FeatherIcon,
      path: '/proker',
    },
    {
      type: 'single_menu',
      title: 'Konten program',
      icon: 'instagram', // use FeatherIcon,
      path: '/konten',
    },
    {
      type: 'single_menu',
      title: 'Logbook',
      icon: 'clipboard', // use FeatherIcon,
      path: '/logbook',
    },
    {
      type: 'single_menu',
      title: 'Laporan',
      icon: 'book-open', // use FeatherIcon,
      path: '/laporan',
    },
    {
      type: 'single_menu',
      title: 'Sign Out',
      icon: 'log-out', // use FeatherIcon,
      path: '/signout',
    },
  ],
  dpl: [
    {
      type: 'nav_title',
      title: 'Menu Utama',
    },
    {
      type: 'single_menu',
      title: 'Dashboard',
      icon: 'home', // use FeatherIcon,
      path: '/',
    },
    {
      type: 'single_menu',
      title: 'Mahasiswa',
      icon: 'users', // use FeatherIcon,
      path: '/mahasiswa',
    },
    {
      type: 'single_menu',
      title: 'Logbook',
      icon: 'clipboard', // use FeatherIcon,
      path: '/logbook',
    },
    {
      type: 'single_menu',
      title: 'Laporan',
      icon: 'book-open', // use FeatherIcon,
      path: '/laporan',
    },
    {
      type: 'single_menu',
      title: 'Sign Out',
      icon: 'log-out', // use FeatherIcon,
      path: '/signout',
    },
  ],
  evaluator: [
    {
      type: 'nav_title',
      title: 'Menu Utama',
    },
    {
      type: 'single_menu',
      title: 'Dashboard',
      icon: 'home', // use FeatherIcon,
      path: '/',
    },
    {
      type: 'single_menu',
      title: 'Kelompok',
      icon: 'feather', // use FeatherIcon,
      path: '/kelompok',
    },
    {
      type: 'single_menu',
      title: 'Semua Mahasiswa',
      icon: 'users', // use FeatherIcon,
      path: '/mahasiswa',
    },
    {
      type: 'single_menu',
      title: 'Semua Komentar',
      icon: 'clipboard', // use FeatherIcon,
      path: '/mahasiswa',
    },
    {
      type: 'single_menu',
      title: 'Sign Out',
      icon: 'log-out', // use FeatherIcon,
      path: '/signout',
    },
  ],
};

export default config;
