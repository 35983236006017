import React, {useEffect, useState} from 'react'
import {hot} from 'react-hot-loader/root'
import {Provider, useDispatch, useSelector} from 'react-redux'
import {ThemeProvider} from 'styled-components'
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import {ConfigProvider, Spin} from 'antd'
import {getJadwalRequest} from '@my-redux/jadwal/actionCreator'
import store from './redux/store'
import Admin from './routes/admin'
import Mahasiswa from './routes/mahasiswa'
import Dpl from './routes/dpl'
import Evaluator from './routes/evaluator'
import Auth from './routes/auth'
import './static/css/style.css'
import config from './config/config'
import {getProfilePhotoAuth, getStatusStudentReq, logOut} from './redux/authentication/actionCreator'
import NotFound from './container/pages/404'
import NotFoundGroups from './container/pages/NoGroups'

const { theme } = config;
const DoSignOut = () => {
  const { isLoggedIn } = useSelector(state => {
    return {
      isLoggedIn: state.auth.login,
    };
  });
  const dispatch = useDispatch();
  if (isLoggedIn) {
    dispatch(logOut());
  }

  return <Redirect to="/" />;
};

function CheckRole() {
  let redd = <Route exact path="/404" component={NotFound} />;
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (firstTime) {
      dispatch(getJadwalRequest())
        .then(() => dispatch(getProfilePhotoAuth(auth)))
        .then(response => {
          if (auth.role === 'mahasiswa') return dispatch(getStatusStudentReq());
          return response;
        })
        .then(() => setFirstTime(false));
    }
  }, [firstTime]);
  switch (auth.role) {
    case 'admin':
      redd = <Route path="/admin" component={Admin} />;
      break;
    case 'mahasiswa':
      redd = <Route path="/mahasiswa" component={Mahasiswa} />;
      if (!auth.statusStudent && !firstTime) {
        return <NotFoundGroups />;
      }
      break;
    case 'dpl':
      redd = <Route path="/dpl" component={Dpl} />;
      break;
    case 'evaluator':
      redd = <Route path="/evaluator" component={Evaluator} />;
      break;
    default:
      return <DoSignOut />;
  }
  return !firstTime ? (
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        {redd}
        <Route exact path="/signout" component={DoSignOut} />
        <Route exact path="/">
          <Redirect to={`/${auth.role || ''}`} />
        </Route>
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  ) : (
    <div className="spin">
      <Spin />
    </div>
  );
}

const ProviderConfig = () => {
  const { rtl, isLoggedIn, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });
  const [path, setPath] = useState(window.location.pathname);
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);
  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={path}>
          <Switch>
            <Route path="/">{isLoggedIn ? <CheckRole /> : <Auth />}</Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
