/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

const getCapsChar = char => {
  if (!char) return '';
  const arrChar = char
    .trim()
    .toUpperCase()
    .split(',')[0]
    .split(' ');
  if (arrChar.length === 1) return arrChar[0].charAt(0);
  return arrChar[0].charAt(0) + arrChar[arrChar.length - 1].charAt(0);
};

export { ellipsis, getCapsChar };
