import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Dashboard from './dashboard';
import withAdminLayout from '../../layout/withAdminLayout';

const Proker = lazy(() => import('../../container/mahasiswa/proker'));
const Profile = lazy(() => import('../../container/profile/myProfile'));
const Konten = lazy(() => import('../../container/mahasiswa/konten'));
const Logbook = lazy(() => import('../../container/mahasiswa/logbook'));
const Laporan = lazy(() => import('../../container/mahasiswa/laporan'));

const Mahasiswa = () => {
  const { path } = useRouteMatch();
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path={path} component={Dashboard} />
        <Route exact path={`${path}/proker`} component={Proker} />
        <Route exact path={`${path}/konten`} component={Konten} />
        <Route exact path={`${path}/logbook`} component={Logbook} />
        <Route exact path={`${path}/profile`} component={Profile} />
        <Route exact path={`${path}/laporan`} component={Laporan} />
        <Route exact path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default withAdminLayout(Mahasiswa);
